import axios from "axios";
import { ISupplyDetail } from "@/api/supply-package.api";

export const sendCode = async (
  phone: string,
  ticket: string,
  randstr: string
): Promise<any> => {
  return axios.post(`/apiv2/login/sendCode`, {
    phone,
    ticket,
    randstr,
  });
};

export const loginPhone = async (
  phone: string,
  auth_code: string
): Promise<{ token: string }> => {
  return await axios.post(`/apiv2/v2/login/phone`, {
    phone,
    auth_code,
  });
};

export const getBlindBoxDetail = async (
  privacy_id: string
): Promise<ISupplyDetail> => {
  return axios.get(`/mall-blind-box/privacy/${privacy_id}`);
};

export const getBannerSwiperDetail = async () => {
  return axios.get(`/mall/fracture`);
};

export interface detailItem {
  ensure_character: number;
  ensure_num: number;
  current_count: number;
}

export interface OrderStatusResult {
  box_id: number;
  supply_id: string | number[];
  order_status: number;
  user_id: number;
  appid: string;
  pay_channel: number;
  id: number;
  detail: detailItem;
}

export const orderStatusV2 = async (
  order_no: string
): Promise<OrderStatusResult> => {
  return axios.get(`/mall-blind-box/result/${order_no}`);
};

export const createBlindBoxOrder = async (
  box_id: number,
  pay_channel: number,
  return_url: string
): Promise<{ order_no: string; alipay?: string }> => {
  return axios.post("/apiv2/v2/blindbox/order/", {
    box_id,
    pay_channel,
    return_url,
    num: 1,
  });
};
